<template>
  <div class="newList">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="onLoad"
      >
        <div
          class="list"
          v-for="(item, index) in list"
          :key="index"
          :style="item.daystr && !item.id ? 'width: ' + (screenWidth-20) + 'px; text-align: center; padding-right: 10px;' : ''"
        >
          <div
            class="list_title"
            :style="'width: ' + (screenWidth*0.87-20) + 'px;height: ' + ((screenWidth*0.87-20)/699*223) + 'px;line-height: ' + ((screenWidth*0.87-20)/699*223) + 'px;'"
            v-if="item.daystr && !item.id"
          >
            {{item.daystr}}
          </div>
          <div
            class="list_goods"
            :style="'width: ' + (screenWidth-30)/2 + 'px;'"
            v-if="item.id"
            @click="detail(item.id)"
          >
            <div class="goods_img">
              <van-image
                :src="item.thumb"
                lazy-load
                :width="(screenWidth-30)/2"
                :height="(screenWidth-30)/2"
                fit="cover"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div class="shuiyin">{{strId}}</div>
            </div>
            <div class="goods_title van-multi-ellipsis--l2">{{item.title}}</div>
            <div class="goods_price">&yen;{{item.price}}</div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "newList",
  data() {
    return {
      CurrSize: 10,
      CurrOffset: 0,
      list: [],
      loading: false,
      finished: false,
      nodata: false,
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      daystr: "",
      strId: "",
      newlist: 1,
      title: '',
      fangfa: '',
    }
  },
  mounted() {
    this.strId = localStorage.getItem("DK_STRID")
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    if (this.$route.query.merchid) {
      this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    }else if (localStorage.getItem("DK_MERCHID")) {
      this.merchid = localStorage.getItem("DK_MERCHID");
    }
  },
  activated() {
    this.newlist = this.$route.query.newlist;
    if(this.newlist==1) {
      this.title = '今日主推';
      this.fangfa = 'get_zhutui_list';
    }else {
      this.title = '每日上新';
      this.fangfa = 'get_new_list';
    }
    this.$parent.getmerchname(this.merchid, this.title);
    console.log(this.newlist, "this.newlistthis.newlistthis.newlistthis.newlistthis.newlistthis.newlist");
    if(this.CurrOffset == 0) {
      this.list = [];
      this.daystr = '';
      this.onLoad();
    }
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.CurrOffset = 0;
      this.$router.back();
    },
    // 获取列表
    onLoad() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/" + _that.fangfa,
          _that.$qs.stringify({
            offset: _that.CurrOffset,
            size: _that.CurrSize,
            daystr: _that.daystr,
            merchid: _that.merchid
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false;
          if (res.data.code == 100000) {
            if (res.data.data.daystr) {
              _that.daystr = res.data.data.daystr;
            }
            if (_that.offset == 0) {
              _that.list = [];
            }
            res.data.data.goods.forEach(item => {
              if (item.daystr) {
                _that.list.push({daystr:item.daystr});
              }
              _that.list.push(item);
            });
            
            console.log(_that.list, 'list');
            _that.CurrOffset += res.data.data.goods.length;
            _that.nodata = false;
            _that.finished = false;
            if (res.data.data.goods.length < _that.CurrSize) {
              _that.finished = true;
              if (_that.CurrOffset == 0) {
                _that.finished = true;
              }
            }
          }else {
            _that.nodata = true;
            _that.finished = false;
            if (_that.CurrOffset == 0) {
              _that.finished = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 进入详情页
    detail(id) {
      this.$router.push({
        name: "Details",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid,
          _t: 3
        }
      })
    },
  },
}
</script>
<style lang="less">
.newList {
  width: 100%;
  text-align: left;
  min-height: 100%;
  background-color: #ededed;
  overflow: hidden;
  padding-bottom: 50px;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    padding-left: 10px;
  }
  .list {
    display: inline-block;
    .list_title {
      display: block;
      margin: 10px auto 0;
      background-image: url(https://img.midbest.cn/uploads/image/tousu/2020/12/26/f6ebe8e2f8824b64edd822ca6f28af45.png);
      background-size: 100% 100%;
      overflow: hidden;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
    .list_goods {
      background-color: #fff;
      border-radius: 6px;
      overflow: hidden;
      margin: 10px 10px 0 0;
      font-size: 14px;
      text-align: left;
      .goods_img {
        position: relative;
        .van-image {
          display: block;
        }
      }
      .goods_title {
        height: 40px;
        line-height: 20px;
        margin: 10px;
      }
      .goods_price {
        color: #e42920;
        padding: 0 10px 10px;
      }
    }
  }
  // 水印
  .shuiyin {
    padding: 2px;
    background-color:#fff;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #808080;
    opacity: 0.5;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>